import React from 'react';
import Mailto from 'react-protected-mailto';
import './App.css';

function App() {
  return (
    <div className="App">
      <video className="App-videobg" poster={"./mock-up.jpg"} preload playsinline loop autoPlay>
        <source src={"./mock-up.mp4"} type="video/mp4" />
        <source src={"./mock-up.mp4"} type="video/ogg" />
        <source src={"./mock-up.webm"} type="video/webm" />
        Tu navegador no soporta el tag: video.
      </video>
      <header className="App-content">
        <img src={"./logo.png"} className="App-logo" alt="logo" />
        <h1 className="App-h1">Diseño y Desarrollo Web en WordPress</h1>
        <p>
          <Mailto
            email='contacto@jgservicios.com'
            headers={{subject: 'Contacto desde JGServicios.com'}} />
        </p>
      </header>
    </div>
  );
}

export default App;
